import Layout from "../../layout/layout";
import DriverListDownload from "./parts/driverListDownload";
import OderListPerMonth from "./parts/oderListPerMonth";
import OrderListCustomDate from "./parts/orderListCustomDate"; 
function OrderListPage() {
    

    const body = (
        <>
            <div>
                <DriverListDownload />
            </div>
            <div>
                <OderListPerMonth />
            </div>
            <div>
                <OrderListCustomDate /> 
            </div>
        </>
    );
    return <Layout body={body} title={"Order Lists Download"} />;

}

export default OrderListPage;
